import { gql } from '@apollo/client';

export const NovaTransaction_NovaBuyTransactionFragment = gql`
  fragment NovaTransaction_NovaBuyTransactionFragment on NovaBuyTransaction {
    id
    audAmount
    audNetAmount
    audTradeAmount
    usdTradeAmount
    units
    unitPrice
    usdUnitPrice
    audFxFee
    audResidualAmount
    status
    statusMessage
    cancellable
    createdAt
    completedAt
    asset {
      id
      name
      symbol
      assetGroup
    }
    bankAccount {
      id
      bsb
      accountNumber
    }
  }
`;

export const NovaTransaction_NovaSellTransactionFragment = gql`
  fragment NovaTransaction_NovaSellTransactionFragment on NovaSellTransaction {
    id
    audNetAmount
    audTradeAmount
    usdTradeAmount
    units
    unitPrice
    usdUnitPrice
    audFxFee
    status
    statusMessage
    cancellable
    createdAt
    completedAt
    asset {
      id
      name
      symbol
      assetGroup
    }
    bankAccount {
      id
      bsb
      accountNumber
    }
  }
`;

export const NovaTransaction_NovaRegulatoryFeeTransactionFragment = gql`
  fragment NovaTransaction_NovaRegulatoryFeeTransactionFragment on NovaRegulatoryFeeTransaction {
    id
    audAmount
    status
    statusMessage
    createdAt
    completedAt
    type
  }
`;

export const NovaTransaction_NovaDividendTransactionFragment = gql`
  fragment NovaTransaction_NovaDividendTransactionFragment on NovaDividendTransaction {
    id
    audNetAmount
    audAmount
    usdAmount
    units
    audPerShare
    usdPerShare
    audWithholdingTax
    status
    statusMessage
    createdAt
    completedAt
    asset {
      id
      name
      symbol
      assetGroup
    }
  }
`;

export const NovaTransaction_NovaAccountFeeTransactionFragment = gql`
  fragment NovaTransaction_NovaAccountFeeTransactionFragment on NovaAccountFeeTransaction {
    id
    status
    createdAt
    completedAt
    statusMessage
    audAmount
    audDiscountAmount
    audNetAmount
    coverageStartDate
    coverageEndDate
    bankAccount {
      id
      bsb
      accountNumber
    }
  }
`;

export const NovaTransaction_NovaTransactionsConnectionEdgeFragment = gql`
  fragment NovaTransaction_NovaTransactionsConnectionEdgeFragment on NovaTransactionsConnectionEdge {
    node {
      id
      ...NovaTransaction_NovaBuyTransactionFragment
      ...NovaTransaction_NovaSellTransactionFragment
      ...NovaTransaction_NovaRegulatoryFeeTransactionFragment
      ...NovaTransaction_NovaDividendTransactionFragment
      ...NovaTransaction_NovaAccountFeeTransactionFragment
    }
  }
  ${NovaTransaction_NovaBuyTransactionFragment}
  ${NovaTransaction_NovaSellTransactionFragment}
  ${NovaTransaction_NovaRegulatoryFeeTransactionFragment}
  ${NovaTransaction_NovaDividendTransactionFragment}
  ${NovaTransaction_NovaAccountFeeTransactionFragment}
`;

export const NovaTransactionLimits_NovaAssetFragment = gql`
  fragment NovaTransactionLimits_NovaAssetFragment on NovaAsset {
    id
    transactionLimits {
      createInvestmentPlanAvailable
      investmentPlanNotAvailableMessage
      investmentPlanMinAudAmount
      investmentPlanMaxAudAmount
      directDebitBuyAvailable
      directDebitBuyNotAvailableMessage
      directDebitBuyMinAudAmount
      directDebitBuyMaxAudAmount
      payIdBuyAvailable
      payIdBuyNotAvailableMessage
      payIdBuyMinAudAmount
      payIdBuyMaxAudAmount
      sellAvailable
      sellNotAvailableMessage
      sellMinUnits
      sellMaxUnits
    }
  }
`;

export const SaverTransactionLimits_SaverProductInstanceFragment = gql`
  fragment SaverTransactionLimits_SaverProductInstanceFragment on SaverProductInstance {
    id
    transactionLimits {
      createInvestmentPlanAvailable
      investmentPlanNotAvailableMessage
      directDebitBuyAvailable
      directDebitBuyNotAvailableMessage
      sellAvailable
      sellNotAvailableMessage
    }
  }
`;

export const MoneyTransactionLimits_MoneyInstanceFragment = gql`
  fragment MoneyTransactionLimits_MoneyInstanceFragment on MoneyInstance {
    id
    transactionLimits {
      directDebitDepositAvailable
      directDebitDepositNotAvailableMessage
      directDebitDepositMinAudAmount
      directDebitDepositMaxAudAmount
      withdrawalAvailable
      withdrawalNotAvailableMessage
      withdrawalMinAudAmount
    }
  }
`;

export const AvailableMoney_Fragment = gql`
  fragment AvailableMoney_Fragment on Query {
    contact {
      id
      account {
        id
        moneyAvailableAudBalance
      }
    }
  }
`;

export const ActiveBankAccount_Fragment = gql`
  fragment ActiveBankAccount_Fragment on Query {
    contact {
      id
      account {
        id
        activeBankAccount {
          id
          accountNumber
        }
      }
    }
  }
`;

export const MoneyActivityTransactionItem_MoneyTransactionFragment = gql`
  fragment MoneyActivityTransactionItem_MoneyTransactionFragment on MoneyTransaction {
    id
    type
    audAmount
    status
    createdAt
    lastUpdatedAt
    statusMessage
    productTransaction {
      id
      ... on Application {
        id
        saverProductInstance {
          id
          portfolio
        }
      }
      ... on Redemption {
        id
        saverProductInstance {
          id
          portfolio
        }
      }
      ... on Boost {
        id
        saverProductInstance {
          id
          portfolio
        }
      }
      ... on NovaBuyTransaction {
        id
        asset {
          id
          symbol
        }
        audResidualAmount
      }
      ... on NovaSellTransaction {
        id
        asset {
          id
          symbol
        }
      }
      ... on NovaAccountFeeTransaction {
        id
      }
      ... on NovaDividendTransaction {
        id
        asset {
          id
          symbol
        }
      }
      ... on NovaRegulatoryFeeTransaction {
        id
        type
      }
    }
  }
`;

export const MoneyActivityTransactions_MoneyInstanceFragment = gql`
  fragment MoneyActivityTransactions_MoneyInstanceFragment on MoneyInstance {
    id
    transactions(types: $typeFilters, first: $first, after: $after)
      @connection(key: "money-activity-transactions", filter: ["types"]) {
      edges {
        node {
          id
          ...MoneyActivityTransactionItem_MoneyTransactionFragment
        }
      }
      pageInfo {
        total
        endCursor
        hasNextPage
      }
    }
  }
  ${MoneyActivityTransactionItem_MoneyTransactionFragment}
`;

export const VoyagerTransaction_UnitExchangeFragment = gql`
  fragment VoyagerTransaction_UnitExchangeFragment on UnitExchange {
    id
    ...unitExchangeScalars
    unitPrice {
      id
      ...unitPriceScalars
    }
  }
`;

export const VoyagerTransaction_SaverProductInstanceFragment = gql`
  fragment VoyagerTransaction_SaverProductInstanceFragment on SaverProductInstance {
    id
    portfolio
    portfolioInformation {
      id
      accountFee
      accountFeeBalanceCopy
      managementFeeCopy
    }
  }
`;

export const VoyagerTransaction_ApplicationFragment = gql`
  fragment VoyagerTransaction_ApplicationFragment on Application {
    id
    transactionStatus: status
    audAmount
    estimatedExecutionDate
    cancelDeadline
    requestedAt
    voyagerStatus: status
    schedule {
      id
      ...scheduleScalars
    }
    saverProductInstance {
      id
      ...VoyagerTransaction_SaverProductInstanceFragment
    }
    voyagerApplicationBankAccount: bankAccount {
      id
      ...bankAccountScalars
    }
    unitExchange {
      id
      ...VoyagerTransaction_UnitExchangeFragment
    }
  }
`;

export const VoyagerTransaction_RedemptionFragment = gql`
  fragment VoyagerTransaction_RedemptionFragment on Redemption {
    id
    fullRedemption
    transactionStatus: status
    audAmount
    requestedAt
    voyagerStatus: status
    saverProductInstance {
      id
      ...VoyagerTransaction_SaverProductInstanceFragment
    }
    voyagerRedemptionBankAccount: bankAccount {
      id
      ...bankAccountScalars
    }
    unitExchange {
      id
      ...VoyagerTransaction_UnitExchangeFragment
    }
  }
`;

export const VoyagerTransaction_BoostFragment = gql`
  fragment VoyagerTransaction_BoostFragment on Boost {
    id
    transactionStatus: status
    audAmount
    estimatedExecutionDate
    requestedAt
    audAmount
    voyagerStatus: status
    voyagerBoostBankAccount: bankAccount {
      id
      ...bankAccountScalars
    }
    saverProductInstance {
      id
      ...VoyagerTransaction_SaverProductInstanceFragment
    }
    unitExchange {
      id
      ...VoyagerTransaction_UnitExchangeFragment
    }
    itemGroup {
      id
      items {
        id
        audAmount
        description
        createdAt
      }
      recipe {
        id
        name
        description
        saverProductInstance {
          id
          portfolio
        }
        parameters {
          id
          name
          value
          type
        }
        source {
          ... on WeatherStation {
            id
            postcode
            name
          }
        }
      }
    }
  }
`;
